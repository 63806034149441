import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ContractService } from '@core/api/services/contract.service';
import { Contract } from '@core/api/interfaces/contract';
import { MasterDataService } from '@core/api/services/master-data';
import { map } from 'rxjs/operators';

@Injectable()
export class ContractResolver implements Resolve<Observable<any>> {

  constructor(
    private $contract: ContractService,
    private $master: MasterDataService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Contract> {
    return forkJoin([
      this.$contract.getByHash(route.params.hash),
      this.$master.getByHash(route.params.hash)
    ]).pipe(
      map(res => res[0])
    );
  }

}
