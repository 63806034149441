import { enableProdMode, InjectionToken } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppConfig } from "./app/app-config.service";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

export const APP_CONFIG: InjectionToken<AppConfig> =
  new InjectionToken<AppConfig>("Application Configuration");

if (environment.production) {
  enableProdMode();
}

if (environment.needConfig) {
  fetch("/config/")
    .then((response) => response.json())
    .catch((err) => environment)
    .then((config: AppConfig) => {
      platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    });
} else {
  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: environment }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
