import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Direction } from '../interfaces/direction';

@Injectable()
export class DirectionService {
  constructor(private $fetcher: FetcherService) { }

  get(): Observable<Direction[]> {
    return this.$fetcher.get<Direction[]>('Direction')
      .pipe(map(res => res.data));
  }
}
