import { Injectable } from '@angular/core';

const DURATION = 350;

@Injectable()
export class ScrollService {
  scrollTop() {
    const topOffset = this.getTopOffset();
    let start;

    const animateScroll = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }
      const offset = timestamp - start;
      window.scroll(0, topOffset - topOffset * offset / DURATION);
      if (offset < DURATION) {
        window.requestAnimationFrame(animateScroll);
      }
    };
    window.requestAnimationFrame(animateScroll);
  }

  private getTopOffset() {
    return document.documentElement && document.documentElement.scrollTop ||
      document.body && document.body.scrollTop || 0;
  }
}
