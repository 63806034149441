import { Component, Input, ChangeDetectionStrategy, ElementRef, OnInit } from '@angular/core';
import { TableauReport, TableauReportParams } from '@core/api/interfaces/tableau-report';

declare var tableau: any;

@Component({
  selector: 'bp-tableau-reports',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
  styles: [`
    :host ::ng-deep div + div {
      margin-top: 2rem;
    }
  `]
})
export class TableauReportsComponent implements OnInit {
  @Input() reports: TableauReport[];
  @Input() data: {[key: string]: string} = {};

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if (!this.reports) {
      return;
    }
    this.reports.forEach(report => this.loadView(report));
  }

  private loadView(report: TableauReport) {
    const options = this.parseParams(report);
    if (!options) {
      return;
    }
    const container = document.createElement('div');
    this.element.nativeElement.appendChild(container);
    options.width = options.width ? options.width + 'px' : '100%';
    options.height = `${options.height || 500}px`;
    new tableau.Viz(container, report.url, options);
  }

  private parseParams(report: TableauReport): TableauReportParams|null {
    try {
      const params = JSON.parse(report.params) as TableauReportParams;
      Object.keys(params)
        .forEach(key => params[key] = this.validateParamByKey(params, key));
      return params;
    } catch (error) {
      console.error('Can\'t parse tableua params: ', error);
      return null;
    }
  }

  private validateParamByKey(options: {[k: string]: string}, key: string): string {
    const value = options[key];
    if (typeof value === 'string' && value.startsWith('{{') && value.endsWith('}}')) {
      return this.data[this.normalizeKey(value.slice(2, -2))] || value;
    }
    return value;
  }

  private normalizeKey(key: string): string {
    return key ? key.slice(0, 1).toLowerCase() + key.slice(1) : key;
  }
}
