import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FetcherService } from '../../services/fetcher.service';
import { User } from '../interfaces/user';
import { Router } from '@angular/router';
import { UserTeam } from '../interfaces/user-team';

@Injectable()
export class UserService {
  constructor(
    private $fetcher: FetcherService,
    private $router: Router
  ) { }

  getCurrent(): Observable<User> {
    return this.$fetcher.get<User>('user/current')
      .pipe(
        map((res) => {
          if (!res.data || res.data.id === 0) {
            this.$router.navigate(['/403-error']);
            return null;
          }
          return res.data || null;
        }),
        catchError((err) => {
          console.error(err);
          this.$router.navigate(['/403-error']);
          return empty();
        })
      );
  }

  getTeam(): Observable<UserTeam> {
    return this.$fetcher.get<UserTeam>('user/team')
      .pipe(map(res => res.data));
  }
}
