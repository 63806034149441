import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Doc } from '../interfaces/doc';
import { GetOptions } from '../interfaces/get-options';
import { DocsGroup } from '../interfaces/documents';
import * as Moment from 'moment';
import * as fs from 'file-saver';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class DocService {

  constructor(
    private $fetcher: FetcherService
  ) { }

  get(hash: string, options: GetOptions): Observable<DocsGroup[]> {
    return this.$fetcher.get<Doc[]>(`contractDocument/${hash}`, options)
      .pipe(map(res => this.buildDocsTree(res.data)));
  }

  getFile(link: string, name: string) {
    this.$fetcher.get<string>(link)
      .subscribe(({ data }) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = data + '&response-content-disposition=attachment;';
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(data);
        a.remove();
      });
  }

  openFile(link: string) {
    const windowReference = window.open();
    this.$fetcher.get<Location>(link)
      .subscribe(res => windowReference.location = res.data);
  }

  *getDocsId(group: DocsGroup) {
    if (group.years){
      for (const year of group.years) {
        for (const quarter of year.quarters) {
          for (const doc of quarter.items) {
            yield doc.id;
          }
        }
      }
    }
  }

  getAsZipFile(hash: string, group: DocsGroup) {
    const ids = [...this.getDocsId(group)];
    return this.$fetcher.getZipFile(`files/${hash}/ContractDocumentsAsZip`, ids).pipe(
      tap((resp: HttpResponse<Blob>) => {
        fs.saveAs(resp.body, `${group.name}.zip`);
      }),
      catchError(err => of([]))
    );
  }

  private buildDocsTree(docs: Doc[]): DocsGroup[] {
    const tree: DocsGroup[] = [];
    docs.forEach((doc) => {
      let root = tree.find(g => g.id === doc.parentTypeId);
      let item: DocsGroup;

      if (root) {
        item = root.items.find(i => i.id === doc.typeId);
        if (!item) {
          item = this.buildGroupItem(doc, 'typeId', 'typeName');
          root.items.push(item);
        }
      } else {
        root = this.buildGroupItem(doc, 'parentTypeId', 'parentTypeName');
        item = this.buildGroupItem(doc, 'typeId', 'typeName');
        root.items.push(item);
        tree.push(root);
      }

      const docPeriod = Moment(doc.period);
      let yearItem = item.years.find(y => y.year === docPeriod.year());
      if (!yearItem) {
        yearItem = {year: docPeriod.year(), quarters: [
          { quarter: 1, hidden: false, items: [] },
          { quarter: 2, hidden: false, items: [] },
          { quarter: 3, hidden: false, items: [] },
          { quarter: 4, hidden: false, items: [] }
        ]};
        item.years.push(yearItem);
        item.years.sort((a, b) => a.year - b.year);
      }
      const quarter = yearItem.quarters[docPeriod.quarter() - 1];
      quarter.items.push(doc);
      quarter.items.sort((a, b) => Moment(a.period).diff(Moment(b.period)));
      root.items.sort((a, b) => a.order - b.order);
    });
    return tree;
  }

  private buildGroupItem(doc: Doc, idKey: string, key: string): DocsGroup {
    const item: DocsGroup = {
      id: doc[idKey],
      name: doc[key],
      order: doc.order,
      hidden: false,
      items: []
    };
    if (idKey === 'typeId') {
      item.years = [];
    }
    return item;
  }
}
