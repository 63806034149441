import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Scheme } from '../interfaces/scheme';
import { ServerResponse } from '../interfaces/response';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class SchemeService {

  constructor(
    private $fetcher: FetcherService,
    private sanitization: DomSanitizer
  ) { }

  get(hash: string): Observable<Scheme[]> {
    // return this.$fetcher.getJson('schemes.json').pipe(
    //   map((res: ServerResponse<Scheme[]>) => {
    //     res.data.forEach((scheme) => {
    //       scheme.safeLink = this.sanitization
    //         .bypassSecurityTrustStyle(`url(${scheme.link})`);
    //     });
    //     return res.data;
    //   })
    // );
    return this.$fetcher.get(['ContractMetroScheme', hash].join('/')).pipe(
      map((res: ServerResponse<Scheme[]>) => {
        res.data.forEach((scheme) => {
          scheme.safeLink = this.sanitization
            .bypassSecurityTrustStyle(`url(${scheme.link})`);
        });
        return res.data;
      })
    );
  }

  getSchemeFile(link: string, name: string) {
    this.$fetcher.get(link)
      .subscribe(({ data }: ServerResponse<string>) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = data + '&response-content-disposition=attachment;';
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(data);
        a.remove();
      });
  }
}
