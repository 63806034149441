import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbededPipe } from './pipes/embeded.pipe';
import { NumeralPipe } from './pipes/numeral.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { ScrollService } from './services/scroll.service';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TableauReportsComponent } from './tableau-reports/tableau-reports.component';
import { ChipsComponent } from './chips/chips.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { InitialsPipe } from './pipes/initials.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { LoaderComponent } from './loader/loader.component';
import { LetDirective } from './directives/let.directive';

@NgModule({
  imports: [CommonModule],
  providers: [ScrollService],
  declarations: [
    EmbededPipe,
    NumeralPipe,
    InitialsPipe,
    WithLoadingPipe,
    TooltipDirective,
    DraggableDirective,
    LetDirective,
    ChipsComponent,
    MultiSelectComponent,
    MonthPickerComponent,
    DatePickerComponent,
    TableauReportsComponent,
    LoaderComponent,
  ],
  exports: [
    EmbededPipe,
    NumeralPipe,
    InitialsPipe,
    WithLoadingPipe,
    TooltipDirective,
    DraggableDirective,
    LetDirective,
    ChipsComponent,
    MultiSelectComponent,
    MonthPickerComponent,
    DatePickerComponent,
    TableauReportsComponent,
    LoaderComponent,
  ],
})
export class SharedModule {}
