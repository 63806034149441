import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable } from 'rxjs';
import { TableauReport } from '../interfaces/tableau-report';
import { map } from 'rxjs/operators';

interface Params {
  code: string;
}

@Injectable()
export class TableauService {
  constructor(
    private $fetcher: FetcherService
  ) { }

  getReportsByCode(hash: string, params: Params): Observable<TableauReport[]> {
    return this.$fetcher.get<TableauReport[]>(`TableauReports/${hash}`, params)
      .pipe(map(res => res.data));
  }
}
