import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class ThemeService {
  @Output() theme$ = new EventEmitter<boolean>();

  darkTheme = false;

  constructor() {
    const darkTheme = localStorage.getItem('darkTheme');
    if (darkTheme === '1') {
      this.darkTheme = true;
      this.theme$.emit(this.darkTheme);
    }
  }

  toggleTheme() {
    this.darkTheme = !this.darkTheme;
    this.theme$.emit(this.darkTheme);
    localStorage.setItem('darkTheme', this.darkTheme ? '1' : '0');
  }
}
