import { Component, HostBinding, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PikAuthService } from 'pik-header';
import * as Moment from 'moment';
import { ThemeService } from '@core/services/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AppConfigService } from './app-config.service';
import { Chart } from 'chart.js';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bank-root',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class.dark-theme') darkTheme: boolean;

  constructor(
    public $auth: PikAuthService,
    private $theme: ThemeService,
    private $config: AppConfigService,
    private $title: Title,
    private overlay: OverlayContainer,
  ) {
    Moment.locale('ru');
    Moment.updateLocale('ru', {
      monthsShort: [
        'янв',
        'фев',
        'мар',
        'апр',
        'май',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек',
      ],
    });
    Chart.defaults.global.defaultFontFamily = "'Graphik', arial, helvetica, sans-serif";
    this.$auth.authCallbackIfRequired();
  }

  ngOnInit() {
    this.darkTheme = this.$theme.darkTheme;
    this.setOverlayTheme();
    this.$theme.theme$.subscribe((darkTheme) => {
      this.darkTheme = darkTheme;
      this.setOverlayTheme();
    });

    const { title } = this.$config.config;
    this.$title.setTitle(title);
  }

  setOverlayTheme() {
    const darkClassName = 'dark-theme';
    if (this.darkTheme) {
      this.overlay.getContainerElement().classList.add(darkClassName);
    } else {
      this.overlay.getContainerElement().classList.remove(darkClassName);
    }
  }
}
