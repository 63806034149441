import { Pipe, PipeTransform } from '@angular/core';
import { numeral } from '@core/api/services/pivot-data';

@Pipe({
  name: 'numeral'
})
export class NumeralPipe implements PipeTransform {
  transform(input?: number) {
    return numeral(input);
  }
}
