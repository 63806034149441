import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ServerResponse } from '../interfaces/response';
import { Execution, ExecutionGroup } from '../interfaces/execution';
import { GetOptions } from '../interfaces/get-options';
import { ExecutionFile } from '../interfaces/execution-file';
import * as fs from 'file-saver';
import { HttpResponse } from '@angular/common/http';

const progressKinds = [
  { name: 'План', ids: [1, 2], code: 'planned' },
  { name: 'Выполненные', ids: [3], code: 'completed' },
];

const initialOptions = {
  limit: 5,
  offset: 0,
  sortBy: 'bankPlanDate',
  sortType: true,
};

@Injectable()
export class ExecutionsService {
  constructor(private $fetcher: FetcherService) {}

  get(hash: string, options: GetOptions): Observable<ServerResponse<Execution[]>> {
    return this.$fetcher
      .get<Execution[]>(`contractExecution/${hash}`, { ...initialOptions, ...options })
      .pipe(
        map((res) => {
          res.data.forEach((execution) => {
            execution.bopFactDocuments.forEach(
              (doc) => (doc.extension = doc.link.substr(doc.link.lastIndexOf('.'))),
            );
          });
          return res;
        }),
      );
  }

  getGroups(
    hash: string,
    commonOptions: GetOptions = {},
    groupOptions: GetOptions[] = [],
  ): Observable<ExecutionGroup[]> {
    return forkJoin(
      progressKinds.map((kind, index) => {
        return this.get(
          hash,
          Object.assign({}, initialOptions, commonOptions, groupOptions[index] || {}, {
            bopProgressKindIds: kind.ids,
          }),
        );
      }),
    ).pipe(
      map((res) => {
        const groups = res
          .map((group, i) => {
            return {
              name: progressKinds[i].name,
              kindIds: progressKinds[i].ids,
              kindCode: progressKinds[i].code,
              entries: group.data,
              meta: group.meta,
            };
          })
          .filter((x) => x.entries.length > 0);
        return groups;
      }),
    );
  }

  getFile(file: ExecutionFile) {
    this.$fetcher.get(file.link).subscribe(({ data }: ServerResponse<string>) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = data + '&response-content-disposition=attachment;';
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(data);
      a.remove();
    });
  }

  getAsZipFile(hash: string, execution: Execution) {
    return this.$fetcher
      .getZipFile(`files/${hash}/ExecutionDocumentsAsZip/${execution.executionId}`, null)
      .pipe(
        tap((resp: HttpResponse<Blob>) => {
          fs.saveAs(resp.body, `${execution.name}.zip`);
        }),
        catchError((err) => of([])),
      );
  }
}
