import { MasterDataService } from '@core/api/services/master-data';
import { GetOptions } from './../interfaces/get-options';
import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PivotGetParams } from '../interfaces/pivot-get-params';
import { Plot, PlotLine, PlotPoint } from '../interfaces/plot';
import * as Moment from 'moment';

interface IPlotOptions {
  from: string;
  to: string;
  stacked?: boolean;
  code?: string;
}

interface IMasterDataLabel {
  _id: number;
  sortOrder: number;
  name: string;
  shortName: string;
}

export function numeral(n?: number | null): string | null {
  if (n == null) {
    return null;
  }
  return String(n).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function parsePlotData(params: object): object {
  const options: GetOptions = {};
  Object.keys(params).map((key) => {
    const value = params[key];
    switch (key) {
      case 'dateStart':
      case 'from':
        options[key] = value.format('YYYY-MM-DD');
        break;
      case 'dateEnd':
      case 'to':
        options[key] = value.format('YYYY-MM-DD');
        break;
      default:
        options[key] = value;
    }
  });
  return options;
}

function isMidDay(date: Moment.Moment, diff: number): boolean {
  if (diff < 2) {
    return date.date() % 5 === 3;
  }
  if (diff < 9) {
    return date.date() === 15;
  }
  if (diff < 15) {
    return date.date() === 15 && date.month() % 2 === 0;
  }
  return date.date() === 15 && date.month() % 3 === 1;
}

@Injectable()
export class PivotDataService {
  constructor(private $fetcher: FetcherService, private $master: MasterDataService) {}

  get(hash: string, id: number, options?: PivotGetParams): Observable<Plot> {
    return this.$fetcher.get<Plot>(`ChartData/${hash}/${id}`, parsePlotData(options)).pipe(
      map((res) =>
        this.plotPrepare(res.data, {
          _id: id,
          from: options.dateStart,
          to: options.dateEnd || new Date(),
          stacked: options.stacked,
          code: options.code,
        }),
      ),
    );
  }

  private plotPrepare(plot?: Plot, options?: any): Plot | null {
    if (!plot) {
      return null;
    }
    const readyPlot = this.initReadyPlot(plot, options._id);
    if (!plot.lines) {
      return readyPlot;
    }

    if (readyPlot.xAxis === 'period') {
      readyPlot.labels = this.generateDateTimeLabels(options, readyPlot.periodType);
      readyPlot.lines = plot.lines
        .map((line) => {
          line.points = this.generateDateTimePoints(line, options, readyPlot.periodType);
          return line;
        })
        .sort((a, b) => Number(a.isPlan) - Number(b.isPlan));

      return readyPlot;
    }

    const labels = this.generateMasterDataLabels(
      'contractCostArticleId',
      'contractCostArticles',
      plot.lines.reduce(
        (pr: number[], cr: PlotLine) => pr.concat(cr.values.map((x) => x.contractCostArticleId)),
        [],
      ),
    );
    readyPlot.labels = labels.map((x) => [x.shortName.trim(), x.name].join(';'));
    readyPlot.lines = this.generatePlotLines(plot.lines, labels);
    return readyPlot;
  }

  private initReadyPlot(plot, id: number): Plot {
    return {
      id,
      name: plot.name,
      xAxis: plot.axisX,
      yAxis: plot.axisY,
      exportDocumentId: plot.exportDocumentId,
      defaultContractCashflowArticleIds: plot.defaultContractCashflowArticleIds,
      periodType: plot.periodType,
      numbers: plot.numbers,
      numberValues: plot.numberValues,
      pivots: plot.pivots,
      showAsStage: plot.showAsStage,
      lines: [],
      labels: [],
    };
  }

  private generatePlotLines(lines: PlotLine[], labels: IMasterDataLabel[]) {
    return lines
      .map((line) => {
        line.values.forEach((value: PlotPoint) => {
          const article = this.$master.findByHash(
            'contractCostArticles',
            value.contractCostArticleId,
          );
          value.articleSortOrder = article.sortOrder;
        });
        line.values.sort((a, b) => a.articleSortOrder - b.articleSortOrder);

        let points = [];
        let valueIndex = 0;
        if (line.values && line.values.length > 0) {
          labels.forEach((article) => {
            let value = 0;
            const toCompare = line.values[valueIndex];
            if (toCompare && toCompare.contractCostArticleId === article._id) {
              value = toCompare.value;
              valueIndex = valueIndex + 1;
            }
            points.push({ x: value, y: article.shortName });
          });
        } else {
          points = labels.map((label) => ({
            x: 0,
            y: label.shortName,
          }));
        }
        line.points = points;
        return line;
      })
      .sort((a: PlotLine, b: PlotLine) => Number(a.isPlan) - Number(b.isPlan));
  }

  private generateDateTimePoints(
    line: PlotLine,
    options: IPlotOptions,
    periodType: string,
  ): number[] {
    if (!line.values.length) {
      return [];
    }
    const endDate = Moment(line.values[line.values.length - 1].period);
    const date = Moment(options.from).startOf('day');
    const points = [];

    while (date.isSameOrBefore(endDate)) {
      const pointValue = this.getDateTimePointValue(line.values, date, periodType, options.stacked);
      points.push(
        pointValue == null && options.stacked
          ? points[points.length - 1]
          : Math.round((pointValue || 0) * 1000) / 1000,
      );
      date.add(1 as any, periodType);
    }
    return points;
  }

  private getDateTimePointValue(
    points: PlotPoint[],
    date: Moment.Moment,
    periodType: string,
    stacked?: boolean,
  ): number | null {
    if (periodType === 'month' || periodType === 'quarter') {
      const filteredPoints = points.filter((v) => Moment(v.period).isSame(date, periodType));
      if (!filteredPoints.length) {
        return null;
      }
      if (stacked) {
        const point = filteredPoints[filteredPoints.length - 1];
        return point ? point.exactValue || point.value : null;
      }
      return filteredPoints.reduce((pr, nx) => pr + (nx.exactValue || nx.value || 0), 0);
    }

    const point = points.find((v) => Moment(v.period).isSame(date, 'day'));
    return point ? point.exactValue || point.value : null;
  }

  private generateDateTimeLabels(options: IPlotOptions, periodType: string): string[] {
    const from = Moment(options.from);
    const to = Moment(options.to);
    const monthDiff = Math.ceil(to.diff(from, 'month', true));
    const labels = [];

    const currentDate = from.startOf(periodType as any);
    switch (periodType) {
      case 'quarter':
      case 'month':
        const monthRest = Number(periodType === 'month');
        while (currentDate < to) {
          const monthLabel = currentDate.format('MMM, D');
          const quarterLabel =
            currentDate.month() % 3 === monthRest ? currentDate.format('Q[Q]YYYY') : '';

          labels.push(`;;${monthLabel};${quarterLabel};`);
          currentDate.add(1, periodType);
        }
        break;
      case 'day':
        let isMonthPrint = true;
        while (currentDate.isSameOrBefore(to)) {
          const isMiddleOfMonth = currentDate.date() === 15;
          const dayLabel = currentDate.format('D MMM');
          const dayMidLabel = isMidDay(currentDate, monthDiff) ? currentDate.format('D MMM') : '';
          const monthLabel = isMiddleOfMonth && isMonthPrint ? currentDate.format('MMM, D') : '';
          const quarterLabel =
            isMiddleOfMonth && currentDate.month() % 3 === 1 ? currentDate.format('Q[Q]YYYY') : '';

          labels.push(`${dayLabel};${dayMidLabel};${monthLabel};${quarterLabel};`);
          currentDate.add(1, periodType);

          if (monthDiff > 10 && currentDate.date() === 1) {
            isMonthPrint = !isMonthPrint;
          }
        }
      default:
    }
    return labels;
  }

  private generateMasterDataLabels(
    fieldName: string,
    tableName: string,
    ids: number[],
  ): IMasterDataLabel[] {
    const uniqIds = ids.filter((id, i, arr) => arr.indexOf(id) === i).sort((a, b) => a - b);
    return uniqIds
      .map((id) => {
        const article = this.$master.findByHash('contractCostArticles', id);
        return {
          _id: id,
          sortOrder: article.sortOrder,
          name: article.name,
          shortName: article.shortName,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }
}
