import { NgModule, Optional, SkipSelf } from "@angular/core";
import {
  CommonModule,
  registerLocaleData,
  APP_BASE_HREF,
} from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
  PikCommonModule,
  PikAuthModule,
  PikHeaderModule,
  PIK_AUTH_CONFIG,
} from "pik-header";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import ru from "@angular/common/locales/ru";
registerLocaleData(ru);

import { ApiModule } from "./api/api.module";
import { environment } from "src/environments/environment";
import { IndexResolver } from "./resolvers/index";
import { ContractResolver } from "./resolvers/contract";
import { FetcherService } from "./services/fetcher.service";
import { ThemeService } from "./services/theme.service";
import { SchemesResolver } from "./resolvers/schemes";
import { UserTeamResolver } from "./resolvers/user-team";
import { AppConfigService } from "../app-config.service";

function initAuthFactory(configService: AppConfigService) {
  return configService.config.auth;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule,
    PikCommonModule.forRoot({
      environment: environment.pikEnvironment,
    }),
    {
      ngModule: PikAuthModule,
      providers: [
        {
          provide: PIK_AUTH_CONFIG,
          useFactory: initAuthFactory,
          deps: [AppConfigService],
          multi: false,
        },
      ],
    },
    PikHeaderModule.forRoot(),
  ],
  declarations: [],
  exports: [PikHeaderModule],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    FetcherService,
    ThemeService,
    IndexResolver,
    ContractResolver,
    SchemesResolver,
    UserTeamResolver,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
