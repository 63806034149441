import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/api/services/user.service';
import { User } from '@core/api/interfaces/user';

@Injectable()
export class IndexResolver implements Resolve<Observable<any>> {

  constructor(private $user: UserService) { }

  resolve(): Observable<User> {
    return this.$user.getCurrent();
  }

}
