import {
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'bp-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges {
  model: any[] = [];
  label: string;

  @Input() collection: any[] = [];
  @Input() modelField = 'id';
  @Input() nameField = 'name';
  @Input() placeholder = 'Выберите';
  @Input() selected: number[] = [];
  @Output() changed = new EventEmitter();

  @HostBinding('class.opened') opened: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (event.data?.targetComponent !== this) {
      this.opened = false;
    }
  }

  @HostListener('click', ['$event'])
  clickin(event: any) {
    event.data = { targetComponent: this };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collection) {
      this.initialiseCollection();
    }
  }

  initialiseCollection() {
    if (!this.collection) {
      return;
    }
    this.collection = this.collection.map((x) => ({
      name: x[this.nameField],
      model: x[this.modelField],
      selected: this.selected.some((id) => x[this.modelField] === id),
    }));
    this.update(true);
  }

  add(item) {
    item.selected = true;
    this.model.push(item);
    this.update();
  }

  remove(index) {
    this.model.splice(index, 1)[0].selected = false;
    this.update();
  }

  toggle() {
    this.opened = !this.opened;
  }

  toggleItem(item) {
    item.selected = !item.selected;
    this.update();
  }

  clear() {
    this.collection.forEach((v) => (v.selected = false));
    this.update();
  }

  onChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedOptions = [];
    for (let i = 0; i < target.selectedOptions.length; i++) {
      selectedOptions.push(target.selectedOptions[i].value);
    }
    this.collection.forEach((v) => (v.selected = selectedOptions.some((s) => s == v.model)));
    this.update();
  }

  private update(quiet?: boolean) {
    this.model = this.collection.filter((x) => x.selected);
    this.updateLabel();
    if (!quiet) this.changed.emit(this.model.map((x) => x.model));
  }

  private updateLabel() {
    if (this.model.length > 0) {
      this.label = this.model.length > 1 ? `Выбрано ${this.model.length}` : this.model[0].name;
    } else {
      this.label = '';
    }
  }
}
