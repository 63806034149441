import { Component, HostBinding } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';

@Component({
  selector: 'bp-header-theme-switcher',
  templateUrl: './header-theme-switcher.component.html',
  styleUrls: ['./header-theme-switcher.component.scss']
})
export class HeaderThemeSwitcherComponent {

  @HostBinding('class.dark-theme') darkTheme: boolean;
  @HostBinding('attr.data-label') label: string;

  constructor(
    private $theme: ThemeService
  ) {
    this.darkTheme = this.$theme.darkTheme;
    this.label = this.darkTheme ? 'Включить светлую тему' : 'Включить темную тему';
    this.$theme.theme$.subscribe((darkTheme: boolean) => {
      this.darkTheme = darkTheme;
      this.label = darkTheme ? 'Включить светлую тему' : 'Включить темную тему';
    });
  }

  toggleTheme() {
    this.$theme.toggleTheme();
  }

}
