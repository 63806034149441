import { Inject, Injectable } from "@angular/core";
import { ProjectCode } from "@core/api/interfaces/project-code";
import { PikAuthConfig, PikEnvironment } from "pik-header";
import { APP_CONFIG } from "src/main";

export interface AppConfig {
  production: boolean;
  api: string;
  needAuth: boolean;
  title: string;
  code: ProjectCode;
  logoBase64: string;
  gaId: string;
  auth: PikAuthConfig;
  pikEnvironment: PikEnvironment;
}

@Injectable()
export class AppConfigService {
  config: AppConfig;
  isPM = false;

  constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    this.config = this.appConfig;
    this.isPM = this.config.code === ProjectCode.PM;
  }
}
