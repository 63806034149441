import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "@core/api/services/user.service";
import { UserTeam } from "@core/api/interfaces/user-team";
import { AppConfigService } from "src/app/app-config.service";

@Injectable()
export class UserTeamResolver implements Resolve<Observable<UserTeam>> {
  constructor(private $user: UserService, private $config: AppConfigService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserTeam> {
    if (this.$config.isPM) {
      return this.$user.getTeam();
    }
    return null;
  }
}
