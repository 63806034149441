<div class="select" [class.active]="model.length > 0" (click)="toggle()">
  <div class="select__label">{{ label || placeholder }}</div>
  <div class="select__arrow"></div>
</div>
<select class="select__select" multiple (change)="onChange($event)">
  <option
    *ngFor="let item of collection"
    value="{{ item.model }}"
    [attr.selected]="item.selected ? '' : null"
  >
    {{ item.name }}
  </option>
</select>
<div *ngIf="collection" class="select-list">
  <div
    *ngFor="let item of collection"
    class="select-list__item checkbox"
    [class.active]="item.selected"
    (click)="toggleItem(item)"
  >
    <span class="checkbox-container">
      <svg width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9962 0.336684C11.1784 0.518929 11.1784 0.814406 10.9962 0.996651L4.32953 7.66332C4.14728 7.84556 3.85181 7.84556 3.66956 7.66332L1.00289 4.99665C0.82065 4.81441 0.82065 4.51893 1.00289 4.33668C1.18514 4.15444 1.48062 4.15444 1.66286 4.33668L3.99954 6.67337L10.3362 0.336684C10.5185 0.15444 10.8139 0.15444 10.9962 0.336684Z"
        />
      </svg>
    </span>
    <span class="checkbox-label">{{ item.name }}</span>
  </div>
</div>
