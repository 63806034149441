<section class="updates">
  <h2 class="visually-hidden">Последние новости</h2>
  <div class="updates__wrapper">
    <div class="updates__slider swiper-container">
      <ul class="swiper-wrapper">
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72930</span>
            <span class="updates__heading">Разработка задания на проектирование</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72929</span>
            <span class="updates__heading">Начало окончания согласования поставки</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72928</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72930</span>
            <span class="updates__heading">Разработка задания на проектирование</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72929</span>
            <span class="updates__heading">Начало окончания согласования поставки</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72928</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
        <li class="swiper-slide updates__slide">
          <a href="#" class="updates__card">
            <span class="updates__number">#72927</span>
            <span class="updates__heading">Окончание поставки деревянных дверей</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>
