<div class="month-picker" (click)="toggle()">
  <div class="value">
    <svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00005 0.299988C6.38665 0.299988 6.70005 0.613388 6.70005 0.999988V2.29999H13.3V0.999988C13.3 0.613388 13.6134 0.299988 14 0.299988C14.3866 0.299988 14.7 0.613388 14.7 0.999988V2.29999H17C18.4912 2.29999 19.7001 3.50882 19.7001 4.99999V17C19.7001 18.4912 18.4912 19.7 17 19.7H3.00005C1.50888 19.7 0.300049 18.4912 0.300049 17V4.99999C0.300049 3.50882 1.50888 2.29999 3.00005 2.29999H5.30005V0.999988C5.30005 0.613388 5.61345 0.299988 6.00005 0.299988ZM5.30005 3.69999H3.00005C2.28208 3.69999 1.70005 4.28202 1.70005 4.99999V17C1.70005 17.718 2.28208 18.3 3.00005 18.3H17C17.718 18.3 18.3 17.718 18.3 17V4.99999C18.3 4.28202 17.718 3.69999 17 3.69999H14.7V4.99999C14.7 5.38659 14.3866 5.69999 14 5.69999C13.6134 5.69999 13.3 5.38659 13.3 4.99999V3.69999H6.70005V4.99999C6.70005 5.38659 6.38665 5.69999 6.00005 5.69999C5.61345 5.69999 5.30005 5.38659 5.30005 4.99999V3.69999ZM4.30005 8.99999C4.30005 8.61339 4.61345 8.29999 5.00005 8.29999H15C15.3866 8.29999 15.7 8.61339 15.7 8.99999C15.7 9.38659 15.3866 9.69999 15 9.69999H5.00005C4.61345 9.69999 4.30005 9.38659 4.30005 8.99999Z"
      />
    </svg>
    <div>
      <span>{{ innerValue.start | date: dayMonthFormat }}</span>
      <span *ngIf="innerValue.end && endYear !== startYear">{{ ', ' + startYear }}</span>
      <span *ngIf="showRangeValue">&nbsp;– {{ innerValue.end | date: dayMonthFormat }}</span>
      <span>{{ ', ' + (endYear || startYear) }}</span>
    </div>
  </div>
</div>
<div *ngIf="isOpened" class="picker">
  <ul *ngIf="periodType === 'day'" class="picker__modes">
    <li class="picker__mode" [class.active]="!isDefinedDay" (click)="isDefinedDay = false">
      Месяц
    </li>
    <li class="picker__mode" [class.active]="isDefinedDay" (click)="isDefinedDay = true">
      Конкретная дата
    </li>
  </ul>
  <ng-container *ngIf="!isDefinedDay">
    <div class="picker__year-content">
      <div class="picker__year-list">
        <span
          *ngFor="let year of years"
          class="picker__year"
          [class.picker__year--active]="year === (isFirstClick ? startYear : endYear)"
          (click)="setYear(year)"
        >
          {{ year }}
        </span>
      </div>
    </div>
    <div *ngIf="periodType !== 'quarter'" class="picker__month-list">
      <div
        *ngFor="let month of months; let index = index"
        class="picker__month"
        [class.picker__month--active]="month.active"
        (click)="setMonth(index)"
        (mouseenter)="hover(index)"
      >
        {{ month.name }}
      </div>
    </div>
  </ng-container>
  <bp-date-picker *ngIf="isDefinedDay" [options]="datePickerOptions"> </bp-date-picker>
</div>
