import flatpickr from 'flatpickr';
import { BaseOptions } from 'flatpickr/dist/types/options';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { Component, AfterViewInit, ElementRef, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'bp-date-picker',
  template: '',
})
export class DatePickerComponent implements AfterViewInit, OnDestroy {
  @Input()
  options: BaseOptions;

  picker: any;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    this.picker = flatpickr(this.element.nativeElement, { ...this.options, locale: Russian });
  }

  ngOnDestroy() {
    this.picker.destroy();
  }
}
