import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camera } from '../interfaces/camera';

@Injectable()
export class CameraService {
  constructor(private $fetcher: FetcherService) { }

  get(hash: string): Observable<Camera[]> {
    return this.$fetcher.get<Camera[]>(`ContractCamera/${hash}`)
      .pipe(map(res => res.data));
  }
}
