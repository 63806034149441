<button
  class="account-mobile"
  [tooltip]="' '"
  [placement]="'bottom'"
  [tooltipTitle]="user.fullName"
>
  <svg width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3004 11.4L9.58491 9.9C9.0806 9.62 8.85754 9.32 8.85754 9.05C8.85754 8.4 10.1183 7.48 10.1183 5.2C10.1183 3.3 9.13879 2 7.46098 2C5.59892 2 4.78426 3.6 4.78426 5.21C4.78426 7.33 6.01594 8.29 6.01594 9C6.01594 9.28 5.83167 9.62 5.31767 9.9L2.60215 11.4C1.64202 11.93 2.11723 14 2.11723 14H12.7853C12.7853 14 13.2509 11.93 12.3004 11.4Z"
    />
  </svg>
</button>
<div class="account"></div>
<div class="user">{{ user.fullName }}</div>
<bp-header-theme-switcher></bp-header-theme-switcher>
