import { Component, Input } from '@angular/core';
import { User } from '@core/api/interfaces/user';

@Component({
  selector: 'bp-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent {
  @Input() user: User;
}
