import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Contract } from '../interfaces/contract';
import { ServerResponse } from '../interfaces/response';
import { ContractTeam } from '../interfaces/contract-team';
import { HttpResponse } from '@angular/common/http';

export interface IPlotExportData {
  dateStart: string;
  dateEnd: string;
}

@Injectable()
export class ContractService {

  contracts: Map<string, Contract> = new Map<string, Contract>();

  constructor(
    private $fetcher: FetcherService,
    private sanitization: DomSanitizer
  ) {}

  getByHash(hash: string): Observable<Contract> {
    if (this.contracts.has(hash)) {
      return of(this.contracts.get(hash));
    }
    return this.$fetcher.get('contract/' + hash).pipe(
      map((res: ServerResponse<Contract>) => {
        const contract = this.sanitizeContractImages([res.data])[0];
        this.contracts.set(hash, contract);
        return contract;
      }),
      shareReplay(1)
    );
  }

  getFirstPage(): Observable<Contract[]> {
    return this.$fetcher.get<Contract[]>('contract', { excludeAssociations: true, sortBy: 'sortOrder' })
      .pipe(map(res => this.sanitizeContractImages(res.data)));
  }

  getTeam(hash: string, params?: {isActual: boolean}): Observable<ContractTeam[]> {
    return this.$fetcher.get<ContractTeam[]>(`contract/team/${hash}`, params)
      .pipe(map(res => res.data));
  }

  exportPlotData(id: number, hash: string, data: IPlotExportData): Observable<HttpResponse<Blob>> {
    return this.$fetcher
      .getFileWithParam(`ImportDocument/ExportXlsx/${hash}/${id}`, data);
  }


  sanitizeContractImages(contracts: Contract[]): Contract[] {
    contracts.forEach((contract: Contract) => {
      if (contract.photoLinkPreview) {
        contract.imagePreview = this.sanitization
          .bypassSecurityTrustStyle(`url(${contract.photoLinkPreview})`);
      } else {
        contract.imagePreview = 'url(assets/images/placeholder.svg)';
      }
      if (contract.photoLinkMain) {
        contract.image = this.sanitization
          .bypassSecurityTrustStyle(`url(${contract.photoLinkMain})`);
      } else {
        contract.image = 'url(assets/images/placeholder.svg)';
      }
    });
    return contracts;
  }
}
