import { Directive, ElementRef, Input, Output, OnInit, OnChanges, HostListener, EventEmitter, Renderer2 } from '@angular/core';

class Position {
  constructor(
    public x: number,
    public y: number
  ) { }
}

@Directive({
  selector: '[draggable]'
})
export class DraggableDirective implements OnInit, OnChanges {
  private allowDrag = true;
  private moving = false;
  private orignal: Position = null;
  private oldTrans: Position = new Position(0, 0);
  private tempTrans: Position = new Position(0, 0);
  private oldZIndex = '';
  private oldPosition = '';
  private scaleProp = '';

  @Output() started = new EventEmitter<any>();
  @Output() stopped = new EventEmitter<any>();

  @Input() handle: HTMLElement;
  @Input() scale = 1;

  @Input()
  set ngDraggable(setting: any) {
    if (setting != null && setting !== '') {
      this.allowDrag = !!setting;
      const element = this.handle || this.el.nativeElement;
      this.allowDrag ? this.renderer.addClass(element, 'ng-draggable') : this.renderer.removeClass(element, 'ng-draggable');
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.allowDrag) {
      const element = this.handle || this.el.nativeElement;
      this.renderer.addClass(element, 'ng-draggable');
      this.scaleProp = 'scale(' + this.scale + ')';
    }
  }

  ngOnChanges(changes: any) {
    if (changes.scale) {
      this.scaleProp = 'scale(' + this.scale + ')';
      this.orignal = this.getPosition(
        this.el.nativeElement.offsetWidth * this.scale / 2,
        this.el.nativeElement.offsetHeight * this.scale / 2
      );
      this.moveTo(this.orignal.x, this.orignal.y);
    }
  }

  private getPosition(x: number, y: number) {
    return new Position(x, y);
  }

  private moveTo(x: number, y: number) {
    if (this.orignal) {
      this.tempTrans.x = (x - this.orignal.x) / this.scale;
      this.tempTrans.y = (y - this.orignal.y) / this.scale;
      const value = this.scaleProp + ` translate(
        ${this.tempTrans.x + this.oldTrans.x}px,
        ${this.tempTrans.y + this.oldTrans.y}px
      )`;
      this.renderer.setStyle(this.el.nativeElement, 'transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
      this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);
    }
  }

  private pickUp() {
    // get old z-index and position:
    this.oldZIndex = this.el.nativeElement.style.zIndex ?
      this.el.nativeElement.style.zIndex : '';
    this.oldPosition = this.el.nativeElement.style.position ?
      this.el.nativeElement.style.position : '';

    if (window) {
      this.oldZIndex = window
        .getComputedStyle(this.el.nativeElement, null)
        .getPropertyValue('z-index');
      this.oldPosition = window
        .getComputedStyle(this.el.nativeElement, null)
        .getPropertyValue('position');
    }

    // setup default position:
    let position = 'relative';

    // check if old position is draggable:
    if (this.oldPosition && (
      this.oldPosition === 'absolute' ||
      this.oldPosition === 'fixed' ||
      this.oldPosition === 'relative')) {
      position = this.oldPosition;
    }

    this.renderer.setStyle(this.el.nativeElement, 'position', position);
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'grabbing');
    this.renderer.setStyle(this.el.nativeElement, 'z-index', '10');

    if (!this.moving) {
      this.started.emit(this.el.nativeElement);
      this.moving = true;
    }
  }

  private putBack() {
    if (this.oldZIndex) {
      this.renderer.setStyle(this.el.nativeElement, 'z-index', this.oldZIndex);
    } else {
      this.el.nativeElement.style.removeProperty('z-index');
    }

    if (this.moving) {
      this.stopped.emit(this.el.nativeElement);
      this.moving = false;
      this.oldTrans.x += this.tempTrans.x;
      this.oldTrans.y += this.tempTrans.y;
      this.tempTrans.x = this.tempTrans.y = 0;
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'grab');

    }
  }

  @HostListener('touchstart', ['$event'])
  onDragStart(event: any) {
    event.preventDefault();
    this.orignal = this.getPosition(event.touches[0].clientX, event.touches[0].clientY);
    this.pickUp();
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: any) {
    event.preventDefault();
    event.preventDefault();
    if (this.moving && this.allowDrag) {
      this.moveTo(event.touches[0].clientX, event.touches[0].clientY);
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    event.preventDefault();
    // 1. skip right click;
    // 2. if handle is set, the element can only be moved by handle
    if (event.button === 2 || (this.handle !== undefined && event.target !== this.handle)) {
      return;
    }

    this.orignal = this.getPosition(event.clientX, event.clientY);
    this.pickUp();
  }

  @HostListener('touchend')
  onTouchEnd() {
    this.putBack();
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.putBack();
  }

  @HostListener('document:mouseleave')
  onMouseLeave() {
    this.putBack();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    if (this.moving && this.allowDrag) {
      this.moveTo(event.clientX, event.clientY);
    }
  }

}
