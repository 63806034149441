import { ProjectCode } from '@core/api/interfaces/project-code';

const GA_IDS = {
  [ProjectCode.BANK]: 'UA-118300756-16',
  [ProjectCode.PARTNER]: 'UA-118300756-25',
  [ProjectCode.PM]: 'UA-118300756-26',
  [ProjectCode.SIGMA]: 'UA-118300756-40',
};

export function initGA(code: string, login = 'unauth user', gaId: string) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(script);

  window['dataLayer'] = window['dataLayer'] || [];
  const googleTag = function (key: string, value: any, opt?: {}) {
    window['dataLayer'].push(arguments);
  };
  googleTag('set', { user_id: login });
  googleTag('js', new Date());
  googleTag('config', GA_IDS[code], { user_id: login });
  window['gtag'] = googleTag;
}
