<div class="chips">
  <div *ngFor="let item of data; let i = index" class="chips__chip" [class.chips__chip--hidden]="isHidden(i)">
    {{ item.name }}
  </div>
  <div *ngIf="exceeded" class="chips__chip chips__chip__button" [class.chips__chip--hidden]="expanded" (click)="toggleExpanded()">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="6" fill="none" viewBox="0 0 18 6">
      <circle cx="3" cy="3" r="1.697" fill="#C4C4C4"/>
      <circle cx="9" cy="3" r="1.697" fill="#C4C4C4"/>
      <circle cx="15" cy="3" r="1.697" fill="#C4C4C4"/>
    </svg>
  </div>
  <div *ngIf="exceeded" class="chips__chip chips__chip__button" [class.chips__chip--hidden]="!expanded" (click)="toggleExpanded()">
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none" viewBox="0 0 11 7">
      <path fill="#000" fill-opacity="0" d="M11 5.5H0V0h11z"/>
      <path stroke="#D6D6D6" stroke-width="2" d="M10.352 6.148l-5.5-5.5M.648 6.148l5.5-5.5"/>
    </svg>
  </div>
</div>
