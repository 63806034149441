import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, empty } from 'rxjs';
import { SchemeService } from '@core/api/services/scheme.service';
import { Scheme } from '@core/api/interfaces/scheme';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SchemesResolver implements Resolve<Observable<any>> {

  constructor(
    private $scheme: SchemeService,
    private $router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Scheme[]> {
    return this.$scheme.get(route.parent.params.hash).pipe(
      catchError((err) => {
        this.$router.navigate([route.parent.params.hash, 'error']);
        return empty();
      })
    );
  }

}
