import { Injectable } from '@angular/core';
import { FetcherService } from '@core/services/fetcher.service';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MasterData } from '../interfaces/master-data';
import { ServerResponse } from '../interfaces/response';
import { ContractObject } from '../interfaces/contract-object';
import { ContractCostArticle } from '../interfaces/contract-cost-article';

@Injectable()
export class MasterDataService {
  data: MasterData;
  hashes: Map<
    string,
    Map<number, ContractObject | ContractCostArticle>
  > = new Map<string, Map<number, ContractObject | ContractCostArticle>>();

  constructor(
    private $fetcher: FetcherService
  ) { }

  get(): Observable<MasterData> {
    return this.$fetcher.getJson('masterdata.json').pipe(
      map((res: ServerResponse<MasterData>) => {
        this.generateHashes(res.data);
        this.data = res.data;
        return this.data;
      }),
      catchError((err) => {
        return empty();
      })
    );
    // return this.$fetcher.get('ContractMasterData').pipe(
    // map((res: ServerResponse<MasterData>) => {
    //   this.data = res.data;
    //   return this.data;
    // }),
    //   catchError((err) => {
    //     return empty();
    //   })
    // );
  }

  getByHash(hash): Observable<MasterData> {
    // return this.$fetcher.getJson('masterdata.json').pipe(
    //   map((res: ServerResponse<MasterData>) => {
    //     this.data = res.data;
    //     return this.data;
    //   }),
    //   catchError((err) => {
    //     return empty();
    //   })
    // );
    return this.$fetcher.get(['ContractMasterData', hash].join('/')).pipe(
      map((res: ServerResponse<MasterData>) => {
        this.generateHashes(res.data);
        this.data = res.data;
        return this.data;
      }),
      catchError((err) => {
        return empty();
      })
    );
  }

  findByHash(dict: string, id: number) {
    return this.hashes.get(dict).get(id);
  }

  private generateHashes(data: MasterData) {
    Object.keys(data).forEach((dict: string) => {
      this.hashes.set(dict, new Map<number, ContractObject | ContractCostArticle>());
      data[dict].forEach((entry: ContractObject | ContractCostArticle) => {
        if (!this.hashes.get(dict).has(entry.id)) {
          this.hashes.get(dict).set(entry.id, entry);
        }
      });
    });
  }
}
