import { PikAuthConfig, PikEnvironment } from "pik-header";
import { ProjectCode } from "@core/api/interfaces/project-code";

export const environment = {
  production: false,
  api: "https://api.testbank.pik-digital.ru/api/v1.0/",
  needAuth: true,
  needConfig: true,
  title: "Банковский портал",
  code: ProjectCode.BANK,
  // eslint-disable-next-line
  logoBase64:
    "PHN2ZyB2aWV3Qm94PSIwIDAgNTIgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMTZWMEgxNS41MzMzVjE2SDExLjA1MDVWMy45NjhINC40ODI3NlYxNkgwWk0yOC43NzMxIDYuNzQxMzNMMjEuNjAwNiAxNkgxNy42MTgzVjBIMjIuMTAxVjkuMjU4NjdMMjkuMjczNSAwSDMzLjI1NThWMTZIMjguNzczMVY2Ljc0MTMzWk0zOS44MjM2IDkuNTU3MzNWMTZIMzUuMzQwOFYwSDM5LjgyMzZWNi42MzQ2N0w0NS4xODIgMEg1MC40MTU0TDQ0LjAzNTMgNy43NjUzM0w1MiAxNkg0NS45NzQzTDM5LjgyMzYgOS41NTczM1oiIGZpbGw9IiNGQzRDMDIiLz48L3N2Zz4=",
  gaId: "",
  auth: {
    clientId: "bank_spa",
    scope: "openid profile email offline_access bank_api photo_api",
  } as PikAuthConfig,
  pikEnvironment: PikEnvironment.test,
};
