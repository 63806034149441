import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Moment } from 'moment';

interface IRange {
  start: Date;
  end?: Date;
}
interface DatesRange {
  dateStart?: Moment;
  dateEnd?: Moment;
}
interface IMonth {
  name: string;
  active?: boolean;
}
export interface IMonthPickerData extends IRange {
  isManual: boolean;
}

const MONTHS = [
  { name: 'Январь' },
  { name: 'Февраль' },
  { name: 'Март' },
  { name: 'Апрель' },
  { name: 'Май' },
  { name: 'Июнь' },
  { name: 'Июль' },
  { name: 'Август' },
  { name: 'Сентябрь' },
  { name: 'Октябрь' },
  { name: 'Ноябрь' },
  { name: 'Декабрь' },
];

@Component({
  selector: 'bp-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit, OnChanges {
  innerValue: IRange = { start: new Date() };
  isFirstClick = true;
  isDefinedDay = false;
  isOpened = false;

  years: number[] = [];
  months: IMonth[] = MONTHS;

  datePickerOptions = {
    mode: 'range',
    inline: true,
    onChange: (dates?: Date[]) => {
      if (!dates || dates.length !== 2) {
        return;
      }
      [this.innerValue.start, this.innerValue.end] = dates;
      this.save();
    },
  };

  @Input() dateFormat = 'LLLL';
  @Input() yearFormat = 'yyyy';
  @Input() range: DatesRange;
  @Input() periodType = 'day';
  @Output() datesChange = new EventEmitter<IMonthPickerData>();

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (event.data?.targetComponent !== this) {
      this.isOpened = false;
    }
  }

  @HostListener('click', ['$event'])
  clickin(event: any) {
    event.data = { targetComponent: this };
  }

  get showRangeValue() {
    const { start, end } = this.innerValue;
    if (!end) {
      return false;
    }
    if (this.isDefinedDay && start.getDate() !== end.getDate()) {
      return true;
    }
    return this.endYear !== this.startYear || this.endMonth !== this.startMonth;
  }
  get dayMonthFormat() {
    return this.isDefinedDay ? 'dd LLLL' : this.dateFormat;
  }
  get startMonth() {
    return this.innerValue.start.getMonth();
  }
  get startYear() {
    return this.innerValue.start.getFullYear();
  }
  get endMonth() {
    return this.innerValue.end ? this.innerValue.end.getMonth() : null;
  }
  get endYear() {
    return this.innerValue.end ? this.innerValue.end.getFullYear() : null;
  }

  ngOnChanges() {
    if (this.range) {
      this.innerValue = {
        start: this.range.dateStart ? this.range.dateStart.toDate() : null,
        end: this.range.dateEnd ? this.range.dateEnd.toDate() : null,
      };
    }
    this.initYears();
  }

  ngOnInit() {
    this.ngOnChanges();
  }

  initYears() {
    const today = new Date();
    const year = today.getFullYear();
    this.years = [year - 1, year, year + 1, year + 2];
    if (this.periodType === 'quarter') {
      this.years.push(year + 1, year + 2, year + 3, year + 4);
    }
  }

  setYear(year: number) {
    if (this.periodType === 'quarter') {
      this.setDate(year, 0);
      this.setDate(year, 11, 'end');
      return this.save();
    }
    if (this.isFirstClick) {
      this.setDate(year, this.startMonth);
    } else {
      this.setDate(year, this.endMonth, 'end');
    }
  }

  setMonth(month: number) {
    if (this.periodType === 'quarter') {
      return;
    }
    if (this.periodType === 'month') {
      const startMonth = 3 * Math.floor(month / 3);
      this.setDate(this.startYear, startMonth);
      this.setDate(this.startYear, startMonth + 2, 'end');
      return this.save();
    }

    if (this.isFirstClick) {
      this.setDate(this.startYear, month);
      this.setDate(this.startYear, month, 'end');
      return (this.isFirstClick = false);
    }

    const endYear = this.endYear;
    if (this.startYear < this.endYear) {
      this.setDate(this.endYear, month, 'end');
    } else if (this.startYear > this.endYear) {
      this.setDate(this.startYear, this.startMonth, 'end');
      this.setDate(endYear, month);
    } else if (this.startMonth === month) {
      delete this.innerValue.end;
    } else if (month < this.startMonth) {
      this.setDate(this.startYear, this.startMonth, 'end');
      this.setDate(endYear, month);
    } else {
      this.setDate(this.startYear, month, 'end');
    }
    this.save();
  }

  hover(index: number) {
    if (this.periodType === 'month') {
      const startIndex = 3 * Math.floor(index / 3);
      this.months.forEach((x, i) => (x.active = i >= startIndex && i < startIndex + 3));
      return;
    }
    if (!this.isFirstClick) {
      let min = Math.min(index, this.startMonth);
      let max = Math.max(index, this.startMonth);
      if (this.endYear > this.startYear) {
        min = 0;
        max = index;
      } else if (this.endYear < this.startYear) {
        max = 12;
        min = index;
      }
      this.months.forEach((x, i) => (x.active = i >= min && i <= max));
    }
  }

  private save() {
    this.isFirstClick = true;
    this.datesChange.emit({ ...this.innerValue, isManual: this.isDefinedDay });
    setTimeout(() => {
      this.isOpened = false;
      this.months.forEach((m) => (m.active = false));
    }, 200);
  }

  private setDate(year: number, month: number, key = 'start') {
    this.innerValue[key] = new Date(year, month);
  }

  toggle(event?: any) {
    this.isOpened = !this.isOpened;
    if (event) {
      event.data = { targetComponent: this };
    }
  }
}
