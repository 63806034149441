import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ServerResponse } from "../api/interfaces/response";
import { Observable } from "rxjs";
import { PikAuthService } from "pik-header";
import { AppConfigService } from "src/app/app-config.service";

/**
 * Базовый сервис
 *
 * @export
 * @class Fetcher
 *
 * @todo кэширование (в observable сервисах) и обработку ошибок
 */
@Injectable()
export class FetcherService {
  protected actionUrl: string;

  get config() {
    return this.configService.config;
  }

  /**
   * Создает экземпляр объекта Fetcher
   *
   * @param {HttpClient} http
   * @param {ErrorHandler} errorHandler
   * @memberof Fetcher
   */
  constructor(
    protected http: HttpClient,
    private configService: AppConfigService,
    private authService: PikAuthService
  ) {}
  /**
   * Выполняет get-запрос
   *
   * @template T
   * @param {string} url
   * @param {HttpParams} [paramsObject=new HttpParams]
   * @returns {Observable<ServerResponse<T>>}
   * @memberof Fetcher
   */
  get<T>(
    url: string,
    paramsObject: any = new HttpParams()
  ): Observable<ServerResponse<T>> {
    let params = new HttpParams();
    const headers = this.buildHeaders();

    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      url = `${this.config.api}${url}`;
    }

    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] !== null) {
        params = params.append(key, paramsObject[key]);
      }
    }

    const options = {
      headers,
      params,
      withCredentials: true,
    };

    return this.http.get<ServerResponse<T>>(`${url}`, options);
  }
  /**
   * Выполняет post-запрос
   *
   * @template T
   * @param {string} url
   * @param {object} data
   * @returns {Observable<ServerResponse<T>>}
   * @memberof Fetcher
   */
  post<T>(url: string, data: object): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.config.api}${url}`, data, {
      headers: this.buildHeaders(),
      withCredentials: true,
    });
  }
  /**
   * Загрузка формы с файлом
   *
   * @template T
   * @param {string} url
   * @param {object} data
   * @returns {Observable<ServerResponse<T>>}
   * @memberof Fetcher
   */
  // upload<T>(url: string, data: object): any {
  //   const req = new HttpRequest('POST', `${environment.api}${url}`, data, {
  //     headers: this.buildHeaders(),
  //     withCredentials: true,
  //     reportProgress: true,
  //   });

  //   return this.http.request(req);
  // }
  /**
   * Выполняет put-запрос
   *
   * @template T
   * @param {string} url
   * @param {object} [data]
   * @returns {Observable<ServerResponse<T>>}
   * @memberof Fetcher
   */
  put<T>(url: string, data?: object): Observable<ServerResponse<T>> {
    return this.http.put<ServerResponse<T>>(`${this.config.api}${url}`, data, {
      headers: this.buildHeaders(),
      withCredentials: true,
    });
  }
  /**
   * Выполняет delete-запрос
   *
   * @template T
   * @param {string} url
   * @returns {Observable<ServerResponse<T>>}
   * @memberof Fetcher
   */
  delete<T>(url: string): Observable<ServerResponse<T>> {
    return this.http.delete<ServerResponse<T>>(`${this.config.api}${url}`, {
      headers: this.buildHeaders(),
      withCredentials: true,
    });
  }

  getJson<T>(mockName: string): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`mock/${mockName}`);
  }

  getFile(url: string) {
    return this.http.get(url, {
      responseType: "blob",
      headers: this.buildHeaders(),
    });
  }

  getFileWithParam(
    url: string,
    paramsObject: any = new HttpParams()) {
      let params = new HttpParams();

      for (const key of Object.keys(paramsObject)) {
        if (paramsObject[key] !== null) {
          params = params.append(key, paramsObject[key]);
        }
      }

      return this.http.get(url, {
        responseType: "blob",
        params: params,
        withCredentials: true,
        observe: "response",
        headers: this.buildHeaders(),
      });
  }

  getZipFile(url: string, data: object) {
    return this.http.post<Blob>(`${this.config.api}${url}`, data, {
      headers: this.buildHeaders(),
      withCredentials: true,
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  private buildHeaders(): HttpHeaders {
    // HttpHeaders - immutable поэтому .set идёт сразу при объявлении
    let headers = new HttpHeaders();

    if (this.config.needAuth) {
      headers = headers.set(
        "Authorization",
        this.authService.getAuthorizationHeaderValue()
      );
    }
    return headers;
  }
}
