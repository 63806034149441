import { NgModule } from '@angular/core';
import { UserService } from './services/user.service';
import { ContractService } from './services/contract.service';
import { ExecutionsService } from './services/execution.service';
import { DocService } from './services/doc.service';
import { MasterDataService } from './services/master-data';
import { SchemeService } from './services/scheme.service';
import { CameraService } from './services/camera.service';
import { PivotDataService } from './services/pivot-data';
import { TableauService } from './services/tableau.service';
import { DirectionService } from './services/direction.service';

/**
 * Модуль с сервисами к API
 *
 * @export
 * @class ApiModule
 */
@NgModule({
  imports: [],
  declarations: [],
  providers: [
    UserService,
    ContractService,
    DocService,
    ExecutionsService,
    SchemeService,
    CameraService,
    PivotDataService,
    MasterDataService,
    TableauService,
    DirectionService
  ],
})
export class ApiModule { }
