import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { User } from "@core/api/interfaces/user";
import { Observable } from "rxjs";
import { map, tap, filter } from "rxjs/operators";
import { initGA } from "../analytics";
import { AppConfigService } from "../app-config.service";

@Component({
  selector: "bp-resolve",
  templateUrl: "./resolve.component.html",
  styleUrls: ["./resolve.component.scss"],
})
export class ResolveComponent implements OnInit {
  user$: Observable<User>;
  hideOnMobile$: Observable<boolean>;
  private isInitGA = false;

  constructor(
    private $route: ActivatedRoute,
    private $router: Router,
    private $config: AppConfigService
  ) {}

  ngOnInit() {
    this.user$ = this.$route.data.pipe(
      map((data: { currentUser: User }) => data.currentUser),
      tap((user) => user && this.initAnalytics(user))
    );
    this.hideOnMobile$ = this.$router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url.length > 1)
    );
  }

  private initAnalytics(user: User) {
    const { code, gaId } = this.$config.config;
    if (!this.isInitGA && gaId) {
      initGA(code, user.login || user.email, gaId);
      this.isInitGA = true;
    }
  }
}
