import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexResolver } from './core/resolvers/index';
import { PikAuthGuard } from 'pik-header';
import { ResolveComponent } from './resolve/resolve.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

const routes: Routes = [
  // ToDo: fix 403 weird redirect
  { path: '403-error', component: ForbiddenComponent },
  {
    path: '',
    component: ResolveComponent,
    canActivate: [PikAuthGuard],
    resolve: {
      currentUser: IndexResolver
    },
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/contracts/contracts.module').then(m => m.ContractsModule)
      }
    ]
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
