import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HeaderUpdatesComponent } from './header/header-updates/header-updates.component';
import { CoreModule } from './core/core.module';
import { HeaderNavComponent } from './header/header-nav/header-nav.component';
import { HeaderThemeSwitcherComponent } from './header/header-theme-switcher/header-theme-switcher.component';
import { ResolveComponent } from './resolve/resolve.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PikErrorPagesModule } from 'pik-header';
import { AppConfigService } from './app-config.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderNavComponent,
    HeaderThemeSwitcherComponent,
    HeaderUpdatesComponent,
    ResolveComponent,
    PageNotFoundComponent,
    ForbiddenComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PikErrorPagesModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [AppConfigService, { provide: LOCALE_ID, useValue: 'ru-RU' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
