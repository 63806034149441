import { Component, Input, OnInit } from '@angular/core';
import { User } from '@core/api/interfaces/user';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'bp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // mobileNavOpened = false;
  logoBase64: SafeHtml;
  title: string;

  @Input() user: User;

  constructor(private $config: AppConfigService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const { logoBase64, title } = this.$config.config;
    this.logoBase64 = this.sanitizer.bypassSecurityTrustHtml(atob(logoBase64));
    this.title = title;
  }

  // mobileNavToggle() {
  //   this.mobileNavOpened = !this.mobileNavOpened;
  // }
}
