import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bp-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() maxCount: number = 4;

  exceeded = false;
  expanded = false;

  ngOnInit() {
    this.exceeded = this.data.length > this.maxCount;
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  isHidden(i: number): boolean {
    if (this.expanded) {
      return false;
    }
    return i >= this.maxCount;
  }
}
