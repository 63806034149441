import { Directive, Input, ElementRef, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';
import { numeral } from '@core/api/services/pivot-data';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy {
  tooltip: HTMLElement;
  offset = 10;

  @Input('tooltip') tooltipText: string;
  @Input() tooltipTitle: string;
  @Input() numeral: boolean;
  @Input() tooltipRest = '';
  @Input() placement = 'top';

  constructor(
    private $el: ElementRef,
    private $renderer: Renderer2,
    private $theme: ThemeService,
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip && this.tooltipText) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  ngOnDestroy() {
    if (this.tooltip && this.tooltip.parentNode === document.body) {
      this.$renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.$renderer.addClass(this.tooltip, 'g-tooltip--show');
  }

  hide() {
    this.$renderer.removeClass(this.tooltip, 'g-tooltip--show');
    setTimeout(() => {
      if (this.tooltip && this.tooltip.parentNode === document.body) {
        this.$renderer.removeChild(document.body, this.tooltip);
      }
      this.tooltip = null;
    }, 100);
  }

  create() {
    this.tooltip = this.$renderer.createElement('span');

    if (this.tooltipTitle) {
      const title = this.$renderer.createElement('span');
      this.$renderer.addClass(title, 'g-tooltip__title');
      this.$renderer.appendChild(title, this.$renderer.createText(this.tooltipTitle));
      this.$renderer.appendChild(this.tooltip, title);
    }

    const text = this.numeral ? numeral(Number(this.tooltipText)) : this.tooltipText;
    this.$renderer.appendChild(
      this.tooltip,
      this.$renderer.createText(text + ' ' + this.tooltipRest),
    );
    this.$renderer.appendChild(document.body, this.tooltip);

    this.$renderer.addClass(this.tooltip, 'g-tooltip');
    this.$renderer.addClass(this.tooltip, `g-tooltip--${this.placement}`);
    if (this.$theme.darkTheme) {
      this.$renderer.addClass(this.tooltip, 'g-tooltip--white');
    }
  }

  setPosition() {
    const hostPos = this.$el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollPosX =
      window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;

    let top = 0;
    let left = 0;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }
    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }
    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }
    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    let leftContain = Math.min(Math.max(left, 8), document.body.clientWidth - tooltipPos.width - 8);
    this.$renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.$renderer.setStyle(this.tooltip, 'left', `${leftContain}px`);

    const leftAlter = hostPos.left + hostPos.width / 2 - leftContain;
    this.tooltip.style.setProperty('--left-after', `${leftAlter}px`);
  }
}
